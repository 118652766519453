import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

import Layout from './../layouts'
import { linkResolver } from './../helpers/linkResolver';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!'

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'hoornbeweegt',
  linkResolver: linkResolver
})